import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"

const firebaseConfig = {
  apiKey: "AIzaSyAlO5qJM1M5iklj8QSIPa-Smb3tLEyaNno",
  authDomain: "editrice-san-marco.firebaseapp.com",
  projectId: "editrice-san-marco",
  storageBucket: "editrice-san-marco.appspot.com",
  messagingSenderId: "252312140572",
  appId: "1:252312140572:web:3994491b77f961519958d9",
}
let auth = null
let db = null

if (typeof window !== "undefined") {
  firebase.initializeApp(firebaseConfig)
  auth = firebase.auth()
  db = firebase.firestore()
  db.settings({
    timestampsInSnapshots: true,
  })
}

export default auth
export { db }
