import React, { useEffect, useState } from "react"
import auth, { db } from "../services/firebase"
export const UserContext = React.createContext()
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null)

  useEffect(() => {
    auth.onAuthStateChanged(user => {
      console.log("USER CONTEXT")
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        console.log("USER", user)
        db.collection("users")
          .where("email", "==", user.email)
          .get()
          .then(querySnapshot => {
            if (querySnapshot.empty) {
              console.log("EMPTY")
              setUser({
                ...user,
                isVerified: true,
              })
            } else {
              querySnapshot.forEach(doc => {
                // doc.data() is never undefined for query doc snapshots
                console.log(doc.id, " => ", doc.data().isVerified)
                const userSnapshot = {
                  ...user,
                  isVerified: doc.data().isVerified === false ? false : true,
                }
                console.log(userSnapshot)
                setUser(userSnapshot)
              })
            }
          })
          .catch(error => {
            console.log(user)
            setUser({
              ...user,
              isVerified: false,
            })
            console.log("Error getting documents: ", error)
          })
        console.log("END", user)
        // ...
      } else {
        // User is signed out
        setUser(user)
        // ...
      }
    })
  }, [])

  return (
    <UserContext.Provider value={{ user }}>{children}</UserContext.Provider>
  )
}
