// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-area-docenti-js": () => import("./../../../src/pages/area-docenti.js" /* webpackChunkName: "component---src-pages-area-docenti-js" */),
  "component---src-pages-contatti-js": () => import("./../../../src/pages/contatti.js" /* webpackChunkName: "component---src-pages-contatti-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-registrazione-confermata-js": () => import("./../../../src/pages/registrazione-confermata.js" /* webpackChunkName: "component---src-pages-registrazione-confermata-js" */),
  "component---src-pages-ricerca-js": () => import("./../../../src/pages/ricerca.js" /* webpackChunkName: "component---src-pages-ricerca-js" */),
  "component---src-templates-agencies-js": () => import("./../../../src/templates/agencies.js" /* webpackChunkName: "component---src-templates-agencies-js" */),
  "component---src-templates-bes-resources-js": () => import("./../../../src/templates/besResources.js" /* webpackChunkName: "component---src-templates-bes-resources-js" */),
  "component---src-templates-book-js": () => import("./../../../src/templates/book.js" /* webpackChunkName: "component---src-templates-book-js" */),
  "component---src-templates-catalogue-js": () => import("./../../../src/templates/catalogue.js" /* webpackChunkName: "component---src-templates-catalogue-js" */),
  "component---src-templates-collection-js": () => import("./../../../src/templates/collection.js" /* webpackChunkName: "component---src-templates-collection-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-news-list-js": () => import("./../../../src/templates/newsList.js" /* webpackChunkName: "component---src-templates-news-list-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-resource-js": () => import("./../../../src/templates/resource.js" /* webpackChunkName: "component---src-templates-resource-js" */),
  "component---src-templates-subject-js": () => import("./../../../src/templates/subject.js" /* webpackChunkName: "component---src-templates-subject-js" */)
}

