/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import "./src/styles/global.css"
import React from "react"
import { AuthProvider } from "./src/context/userContext"
import { setConfiguration } from "react-grid-system"
import { Helmet } from "react-helmet"

setConfiguration({ maxScreenClass: "xl" })

export const wrapRootElement = ({ element }) => (
  <AuthProvider>{element}</AuthProvider>
)
